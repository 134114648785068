import React from 'react';
import About from '../Components/About/About';
import { Helmet } from 'react-helmet';

function AboutPage() {
  return (
    <div>
      <Helmet>
        <title>Energic Crop Science - Leading Pesticides and Fertilizers Company in India</title>
        <meta name="description" content="Learn about Energic Crop Science, a premier company in India, renowned for high-quality pesticides and fertilizers, driving agricultural excellence nationwide." />
        <meta name="keywords" content="Energic Crop Science, pesticides, fertilizers, top company, India, agricultural solutions" />

        <meta name="keyphrase" content='Discover the story behind Energic Crop Science, a pioneering company in the field of agricultural solutions, with a focus on top-quality pesticides and fertilizers.' />
        <meta name="keyphrase" content='Explore the extensive expertise of Energic Crop Science, dedicated to providing innovative and effective solutions for the agriculture industry' />
        <meta name="keyphrase" content='Energic Crop Science serves as a trusted partner to farmers across India, offering top-tier pesticides and fertilizers for diverse agricultural needs.' />
        <meta name="keyphrase" content=' Learn about our commitment to delivering only the highest-quality pesticides and fertilizers to ensure optimal crop growth and protection.' />
        <meta name="keyphrase" content='Energic Crop Science is at the forefront of promoting sustainable farming practices through our advanced agricultural solutions.' />
        <meta name="keyphrase" content='Energic Crop Science is proud to be recognized as one of Indias top companies in the field of pesticides and fertilizers.' />
        <meta name="keyphrase" content='Discover why Energic Crop Science is the preferred choice for farmers seeking reliable and effective agricultural products.' />
        <meta name="keyphrase" content='Learn about the significant impact Energic Crop Science has on Indian agriculture, contributing to increased productivity and sustainability.' />
        <meta name="keyphrase" content='Explore our innovative approach to developing pesticides and fertilizers that address the ever-evolving needs of Indian farmers.' />
        <meta name="keyphrase" content='Energic Crop Science leads the way in the agricultural industry, setting standards for excellence and continuous improvement.' />
        <meta name="keyphrase" content=' We prioritize our customers success, offering tailored solutions and support to farmers across India.' />
        <meta name="keyphrase" content='Energic Crop Science collaborates with farmers and industry experts, fostering strong partnerships that drive positive change.' />
        <meta name="keyphrase" content='Discover our comprehensive range of crop protection solutions, designed to safeguard yields and enhance agricultural output.' />
        <meta name="keyphrase" content='Learn about our nutrient-rich fertilizers, designed to optimize plant growth and improve soil health.' />
        <meta name="keyphrase" content='Meet the dedicated professionals behind Energic Crop Science, working tirelessly to empower Indian farmers' />
        <meta name="keyphrase" content='Energic Crop Science contributes significantly to Indias agricultural growth and self-sufficiency goals.' />
        <meta name="keyphrase" content='Have questions or want to partner with Energic Crop Science? Reach out to us for industry-leading agricultural solutions.' />


        <meta name="About Us" content='Discover the story behind Energic Crop Science, a pioneering company in the field of agricultural solutions, with a focus on top-quality pesticides and fertilizers.' />
        <meta name="Our Expertise" content='Explore the extensive expertise of Energic Crop Science, dedicated to providing innovative and effective solutions for the agriculture industry' />
        <meta name="All India Reach" content='Energic Crop Science serves as a trusted partner to farmers across India, offering top-tier pesticides and fertilizers for diverse agricultural needs.' />
        <meta name="Quality Products" content=' Learn about our commitment to delivering only the highest-quality pesticides and fertilizers to ensure optimal crop growth and protection.' />
        <meta name="Sustainable Agriculture" content='Energic Crop Science is at the forefront of promoting sustainable farming practices through our advanced agricultural solutions.' />
        <meta name="Top Company" content='Energic Crop Science is proud to be recognized as one of Indias top companies in the field of pesticides and fertilizers.' />
        <meta name="Farmers' Choice" content='Discover why Energic Crop Science is the preferred choice for farmers seeking reliable and effective agricultural products.' />
        <meta name="Nationwide Impact" content='Learn about the significant impact Energic Crop Science has on Indian agriculture, contributing to increased productivity and sustainability.' />
        <meta name="Innovative Solutions" content='Explore our innovative approach to developing pesticides and fertilizers that address the ever-evolving needs of Indian farmers.' />
        <meta name="Industry Leadership" content='Energic Crop Science leads the way in the agricultural industry, setting standards for excellence and continuous improvement.' />
        <meta name="Customer-Centric Approach" content=' We prioritize our customers success, offering tailored solutions and support to farmers across India.' />
        <meta name="Trusted Partnerships" content='Energic Crop Science collaborates with farmers and industry experts, fostering strong partnerships that drive positive change.' />
        <meta name="Crop Protection" content='Discover our comprehensive range of crop protection solutions, designed to safeguard yields and enhance agricultural output.' />
        <meta name="Nutrient-Rich Fertilizers" content='Learn about our nutrient-rich fertilizers, designed to optimize plant growth and improve soil health.' />
        <meta name="About Our Team" content='Meet the dedicated professionals behind Energic Crop Science, working tirelessly to empower Indian farmers' />
        <meta name="Contributing to India's Growth" content='Energic Crop Science contributes significantly to Indias agricultural growth and self-sufficiency goals.' />
        <meta name="Contact Us" content='Have questions or want to partner with Energic Crop Science? Reach out to us for industry-leading agricultural solutions.' />


        <meta name="topic" content="Pesticides and Fertilizers" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Best Pesticides & Fertilizers | Energic Crop Science" />
        <meta name="twitter:description" content="Explore our top-rated pesticides and fertilizers at Energic Crop Science. Elevate your crop yield with West Bengal's #1 agricultural solutions, trusted across all of India." />
        <meta name="twitter:image" content="https://www.energiccropscience.com/static/media/productbackground.736e0c606d9b0dfb211a.png" />
        <meta property="og:title" content="Best Pesticides & Fertilizers | Energic Crop Science" />
        <meta property="og:description" content="Explore our top-rated pesticides and fertilizers at Energic Crop Science. Elevate your crop yield with West Bengal's #1 agricultural solutions, trusted across all of India." />
        <meta property="og:image" content="https://www.energiccropscience.com/static/media/productbackground.736e0c606d9b0dfb211a.png" />
        <meta property="og:url" content="https://www.energiccropscience.com/product-details" />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="West Bengal" />
        <meta name="geo.position" content="latitude;longitude" />
        <meta name="ICBM" content="latitude, longitude" />
        <meta name="Author" content='Energic Crop Science' />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="language" content="English" />
        <meta name="googlebot" content="index, follow" />
        <meta name="msnbot" content="index, follow" />
        <meta name="sitemap" content="https://www.energiccropscience.com/sitemap.xml" />
        <meta itemprop="description" content="Searching for Local Road Assistance Service Near Me Mumbai? So you're in the proper spot. We provide a variety of auto repair services to our clients and treat your car." />
        <meta itemprop="url" content="https://energiccropscience.com" />
        <meta name="serp-rank" position="1" />
        <meta name="description" content="Ridcor" />
        <meta name="keywords" content="Ridcor" />
        <meta name="revisit-after" content="7 days" />
        <meta name="rating" content="5 stars" />
        <meta name="availability" content="in stock" />
        <meta name="author" content="Energic Crop Science" />
        <meta name="publisher" content="Energic Crop Science" />
        <meta name="copyright" content="© 2023 Energic Crop Science" />
        <meta name="og:type" content="product" />
        <meta name="msvalidate.01" content="Bing_verification_code" />
        <meta name="google-site-verification" content="Google_verification_code" />
        <meta name="referrer" content="origin" />
        <meta name="pageid" content="product-details" />
        <meta name="classification" content="Pesticides and Fertilizers" />
        <meta name="pagelanguage" content="en-US" />
        <meta name="target" content="all" />
        <meta name="rating" content="5" />
        <meta name="category" content="Agriculture" />
        <meta name='distribution' content="global" />
        <link rel="canonical" href="https://www.energiccropscience.com/about" />
      </Helmet>
      <About />
    </div>
  )
}

export default AboutPage;